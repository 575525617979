var magnificPopup = null;

;(function( window, document ) {
  window.PTLA = window.PTLA || {};

  PTLA.init = function() {

    // Initialize Utility and Common functions
    PTLA.util.init();
    PTLA.common.init();

    // Add "data-page" to body tag to trigger page-specific function
    var page = document.body.getAttribute( "data-page" );

    if (PTLA[page] && typeof PTLA[page]["init"] === "function") {
      PTLA[page]["init"]();
    }

  };

  /*
   * Utility/Helper
   */
  PTLA.util = {
    init: function() {
      var _this = this;

      History.Adapter.bind(window, 'statechange', function() {
        var State = History.getState();
      });

    }
  };

  /*
   * Common/Site-Wide
   */
  PTLA.common = {
    init: function() {
      var _this = this;
      	// Sticky Nav
      	// var sticky = new Waypoint.Sticky({
      	// 	element: $('#nav')[0]
      	// });

         // Mobile Nav
         $('.toggle-menu').click(function () {
            $('.menu-wrapper').toggleClass("show");
         });

         // ===== Scroll to Top ====
         $(window).scroll(function() {
             if ($(this).scrollTop() >= 150) {        // If page is scrolled more than 50px
                 $('#return-to-top').fadeIn(200);    // Fade in the arrow
             } else {
                 $('#return-to-top').fadeOut(200);   // Else fade out the arrow
             }
         });
         $('#return-to-top').click(function() {      // When arrow is clicked
             $('body,html').animate({
                 scrollTop : 0                       // Scroll to top of body
             }, 500);
         });

    }
  };

  /*
   * Page-Specific
   */
  PTLA.home = {
    init: function() {
      var _this = this;
    }
  };

   PTLA.portfolio = {
     init: function() {
        var _this = this;
        $('.js-property').magnificPopup({
        type: 'ajax',
        removalDelay: 500,
        mainClass: 'mfp-fade',
        fixedContentPos: true,
        callbacks: {
          open: function() {
            History.Adapter.bind(window, 'statechange', PTLA.util.closePopup);
            History.pushState(null, null, this.currItem.src);
            magnificPopup = this;
          },
          close: function () {
            $(window).unbind('statechange', PTLA.util.closePopup);
            History.replaceState(null, null, '/portfolio');
            magnificPopup = null;
          }
        }
      });

      // Portfolio Gallery Index
   	var $status = $('.portfolio-index');
   	var $slickElement = $('.portfolio-gallery');

   	$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
   		//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
   		var i = (currentSlide ? currentSlide : 0) + 1;
   		$status.text(i + '/' + slick.slideCount);
   	});

      // Portfolio Gallery
      $('.portfolio-gallery').slick({
       centerMode: true,
       centerPadding: '30%',
       slidesToShow: 1,
       dots: false,
       arrows: true,
       appendArrows: $('.index-wrap'),
       autoplay: false,
       responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '5%',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              centerMode: true,
              centerPadding: '5%',
              slidesToShow: 1
            }
          }
       ]
      });

     }
   };

   PTLA.investmentservices = {
      init: function() {
        var _this = this;

          var myTabs = tabs({
             el: '#tabs',
             tabNavigationLinks: '.tabs-nav-link',
             tabContentContainers: '.tab'
          });
          myTabs.init();
      }
   };

   PTLA.managementservices = {
      init: function() {
        var _this = this;

          var myTabs = tabs({
             el: '#tabs',
             tabNavigationLinks: '.tabs-nav-link',
             tabContentContainers: '.tab'
          });
          myTabs.init();
      }
   };

  PTLA.init();
})( window, document );
